import React from 'react';
import './App.css';
import Footer from './Footer';
import logo from './icons/chunky.webp';

// Platforms
import modrinthIcon from './icons/modrinth.webp';
import spigotIcon from './icons/spigot.webp';
import forgeIcon from './icons/curseforge.svg';
import fabricIcon from './icons/fabric.webp';
import codeMCIcon from './icons/codemc.webp';
import githubIcon from './icons/github.webp';

// Tools
import calcIcon from './icons/calc.webp';

const MainPage = () => {
    return (
        <div id="root">
            <div className="App">
                <header className="App-header">
                    <div className="logo">
                        <img src={logo} className="App-logo" alt="Chunky Logo" />
                    </div>
                    <h1>Chunky</h1>
                    <p>The <i>best</i> pre-generation plugin for Minecraft 1.13+</p>
                    <div className="tools">
                        <a href="/calc" className="tool">
                            <img src={calcIcon} alt="World Size Calculator" className="icon" />
                        </a>
                    </div>
                    <div className="links">
                        <a href="https://github.com/pop4959/Chunky" target="_blank" rel="noopener noreferrer" className="link">
                            <img src={githubIcon} alt="GitHub" className="icon" />
                        </a>
                        <a href="https://modrinth.com/plugin/chunky" target="_blank" rel="noopener noreferrer" className="link">
                            <img src={modrinthIcon} alt="Modrinth" className="icon" />
                        </a>
                        <a href="https://www.spigotmc.org/resources/chunky.81534/" target="_blank" rel="noopener noreferrer" className="link">
                            <img src={spigotIcon} alt="Spigot" className="icon" />
                        </a>
                        <a href="https://www.curseforge.com/minecraft/mc-mods/chunky-pregenerator-forge" target="_blank" rel="noopener noreferrer" className="link">
                            <img src={forgeIcon} alt="Forge" className="icon" />
                        </a>
                        <a href="https://www.curseforge.com/minecraft/mc-mods/chunky-pregenerator" target="_blank" rel="noopener noreferrer" className="link">
                            <img src={fabricIcon} alt="Fabric" className="icon" />
                        </a>
                        <a href="https://ci.codemc.io/view/Author/job/pop4959/job/Chunky/" target="_blank" rel="noopener noreferrer" className="link">
                            <img src={codeMCIcon} alt="CodeMC" className="icon" />
                        </a>
                    </div>
                </header>
                <Footer/>
            </div>
        </div>
    );
}

export default MainPage;