import React from 'react';
import './App.css';
import WorldSizeCalculator from './WorldSize';
import logo from './icons/chunky.webp';
import Footer from './Footer';

const CalcPage = () => {
    return (
        <div id="root">
            <div className="App">
                <header className="App-header">
                    <a href="/">
                        <div className="logo">
                            <img src={logo} className="App-logo" alt="Chunky Logo" />
                        </div>
                    </a>
                    <h1>Chunky</h1>
                    <WorldSizeCalculator/>
                </header>
                <Footer/>
            </div>
        </div>
    );
}

export default CalcPage;